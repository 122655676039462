import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './i18n';


Vue.use(VueI18n);


const i18nInstance = new VueI18n({
  locale: 'en',
  fallbackLocale: ['universal', 'en'],
  messages,
  missing: (...args) => {
    console.log('[temp] [VueI18n-missing] missing=', args); // FIXME PAX temp logs
  },
});

export default i18nInstance;
