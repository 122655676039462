<template>
  <get-started
    :label="companyName"
    :logoUrl="logoURL"
    :selectedLanguage="selectedLanguage"
    :languages="languages"
    logoSize="160px"
    :businessLoginAvailable="false"
    :smsPersonalLoginEnabled="smsPersonalLoginEnabled"
    v-on:startClassic="startClassic"
    v-on:startBusiness="startBusiness"
    v-on:changeLanguage="changeLanguage"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import { triggerFirebaseEvent } from '@/events/firebase';
import {
  getLoginUrl,
  executeRefreshToken,
} from '../../authentication/authenticationManager';
import LanguageSelectorMixin from '../shared/mixins/LanguageSelectorMixin';

export default {
  name: 'GetStarted.vue',
  data() {
    return {
      loginUrl: false,
      selectedLanguage: 'en-uk',
      acceptedEnglishLanguages: ['ca', 'us', 'uk'],
    };
  },
  mixins: [LanguageSelectorMixin],
  computed: {
    ...mapGetters({
      isRefreshingToken: 'authentication/isRefreshingToken',
      companyName: 'bookingChannel/name',
      communicationLanguage: 'bookingChannel/communicationLanguage',
      defaultCountry: 'bookingChannel/defaultCountry',
      logoURL: 'bookingChannel/logo',
      authError: 'globalError/authError',
      businessAccountEnabled: 'features/businessAccountEnabled',
      smsPersonalLoginEnabled: 'bookingChannel/smsPersonalLoginEnabled',
      firebaseAnalytics: 'firebase/firebaseAnalytics',
    }),
    getStartedLoading() {
      return this.isRefreshingToken;
    },
  },
  async beforeCreate() {
    await executeRefreshToken(this.$route.query.from);
  },
  async created() {
    this.selectedLanguage = this.getInitialLanguage({
      bookingChannelLanguage: this.communicationLanguage,
    });

    if (this.selectedLanguage === 'en') {
      this.selectedLanguage = (this.acceptedEnglishLanguages.includes(this.defaultCountry)) ? `en-${this.defaultCountry.toLowerCase()}` : 'en-uk';
    }

    await this.changeLanguage(this.selectedLanguage);
  },
  methods: {
    async setLoginUrl(url) {
      this.loginUrl = url;
    },
    startClassic() {
      triggerFirebaseEvent({
        firebaseAnalytics: this.firebaseAnalytics,
        eventName: 'click_get_started',
      });
      window.location = this.loginUrl;
    },
    async startBusiness() {
      const acrValues = `${process.env.VUE_APP_LEGACY_BUSINESS_ACR_VALUES} ${process.env.VUE_APP_BUSINESS_ACR_VALUES}`;
      const loginUrl = await getLoginUrl(
        { path: '/book' },
        process.env.VUE_APP_PASSENGER_AUDIENCE,
        acrValues
      );
      window.location = loginUrl;
    },
    async changeLanguage(language) {
      await this.selectLanguage(language);
      const loginUrl = await getLoginUrl({ path: '/book' });
      this.setLoginUrl(loginUrl);
    },
  },
};
</script>

<style>
.get-started-language-selector > .v-input__control > .v-input__slot {
  border: none;
}
</style>
