import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

// i18n for dayjs
import en from 'dayjs/locale/en';
import enGb from 'dayjs/locale/en-gb';
import enCa from 'dayjs/locale/en-ca';
import fr from 'dayjs/locale/fr';
import frCa from 'dayjs/locale/fr-ca';
import sv from 'dayjs/locale/sv';

const enUk = Object.assign({}, enGb, { name: 'en' });
const enUs = Object.assign({}, en, { name: 'en-us' });
const enCA = Object.assign({}, enCa, { name: 'en-ca' });
const frFr = Object.assign({}, fr, { name: 'fr' });
const frCA = Object.assign({}, frCa, { name: 'fr-ca' });

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(LocalizedFormat);

dayjs.locale(enUk, null, true);
dayjs.locale(enUs, null, true);
dayjs.locale(enCA, null, true);
dayjs.locale(frFr, null, true);
dayjs.locale(frCA, null, true);
dayjs.locale(sv, null, true);

export default dayjs;
