import { LanguageHelper } from '@/helpers/languageHelper';

export default {
  computed: {
    /**
     * @return {Array<NamedLanguage>}
     */
    supportedLanguages() {
      return this.$i18n.availableLocales
        .filter(name => (name !== 'universal'))
        .map(language => ({
          id: language,
          name: this.$i18n.t(`language.${language}`),
        }));
    },

    /**
     * Gets the list of selectable languages.
     *
     * This should be used instead of "supportedLanguages" whenever
     * the list of language options is going to be displayed
     * to the user.
     *
     * @return {Array<NamedLanguage>}
     */
    languages() {
      return this.supportedLanguages
        .filter(lg => (lg.id !== 'en'));
    },
  },

  methods: {
    async selectLanguage(language) {
      this.$store.dispatch('language/setSelectedLanguage', (this.isLanguageSupported(language) ? language.toLowerCase() : this.getDefaultLanguage()));
    },

    /**
     * @return {LanguageCode|null}
     */
    getLanguageFromBrowserIfSupported() {
      const browserLanguageStack = LanguageHelper.getBrowserLanguageStack();

      for (let i = 0; i < browserLanguageStack.length; i++) {
        const languageCode = browserLanguageStack[i];

        if (this.isLanguageSupported(languageCode)) {
          return languageCode.toLowerCase();
        }

        const masterLanguage = languageCode.split('-')[0];
        if (masterLanguage && this.isLanguageSupported(masterLanguage)) {
          return masterLanguage.toLowerCase();
        }
      }

      return null;
    },

    getInitialLanguage({ bookingChannelLanguage }) {
      const browserLanguage = this.getLanguageFromBrowserIfSupported();
      if (browserLanguage) {
        return browserLanguage;

      } else if (bookingChannelLanguage && this.isLanguageSupported(bookingChannelLanguage)) {
        return bookingChannelLanguage;

      } else {
        return this.getDefaultLanguage();
      }
    },

    /**
     * @param {string} language
     * @return {boolean}
     */
    isLanguageSupported(language) {
      return this.supportedLanguages
        .map(l => l.id)
        .includes(language.toLowerCase());
    },

    getDefaultLanguage() {
      return 'en-uk';
    },
  },
};
