import _ from 'lodash';


export class LanguageHelper {

  /**
   * @return {Array<LanguageCode>}
   */
  static getBrowserLanguageStack() {
    let browserLanguages = [];

    if (navigator) {
      if (navigator.languages) {
        browserLanguages = navigator.languages;
      } else if (navigator.language) {
        browserLanguages = [navigator.language];
      }
    }

    return (browserLanguages)
      .map((langCode) => {
        return LanguageHelper.convertLanguageCodeToStandardFormat(langCode);
      });
  }


  /**
   * @param {string} rawCode
   * @return {LanguageCode}
   */
  static convertLanguageCodeToStandardFormat(rawCode) {
    const lowerCode = _.toLower(rawCode);

    if (lowerCode === 'en-gb' || lowerCode === 'en-ie') {
      /*
      IMPORTANT: The WebBooker codebase expected British English to use
       the language code "en-uk", NOT "en-gb". This is actually an error
       on our part, because "UK" is not the correct ISO code for Britain,
       "GB" is; browsers will never provide "en-uk" as a language code,
       so we must map "en-gb" to "en-uk".
       */
      return 'en-uk';
    }

    return lowerCode;
  }

}
