<template>
  <div>
    <vue-headful
      :title="this.title"
    />
  </div>
</template>

<script>
export default {
  name: 'HtmlHead',
  props: {
    innerTitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    title() {
      return `${this.innerTitle} | ${this.$t('head.title.complement')}`;
    },
  },
};
</script>
