import enUk from './en.json';
import enUs from './en-us.json';
import fr from './fr.json';
import es from './es.json';
import sv from './sv.json';
import fi from './fi.json';
import nl from './nl.json';
import universal from './universal.json';

export default {
  universal,
  en: enUk,
  'en-uk': enUk,
  'en-ca': enUs,
  'en-us': enUs,
  sv,
  fr,
  fi,
  es,
  nl,
};
